<template>
    <div id="content" class="config" :class="[currentPage, $mq]">
        <div id="config_content_scroll" class="content right scrollbars scrollbar-transparent hide-scrollbar">
            <pPrinter v-if="currentPage == 'printer'"></pPrinter>
            <sensor v-if="currentPage == 'sensconfig'"></sensor>
            <pLocation v-if="currentPage == 'location'"></pLocation>
            <pDevice v-if="currentPage == 'device'"></pDevice>
            <pDev v-if="currentPage == 'dev'"></pDev>
            <pDashboard v-if="currentPage == 'dashboard'"></pDashboard>
            <pUsers v-if="currentPage == 'users'"></pUsers>
            <pStatus v-if="currentPage == 'status'"></pStatus>
        </div>
        <template v-if="['landscape', 'desktop'].includes($mq)">
            <div class="column left categories">
                <router-link class="item location" :class="{ selected: currentPage == 'location' }" to="/config/location">{{ $t('config.location.title') }} </router-link>
                <router-link
                    v-if="tools.includes(3)"
                    class="item printer"
                    :class="{ selected: currentPage == 'printer' }"
                    :to="{
                        name: 'ConfigPage',
                        params: { page: 'printer', update: updateR, config_id: configR }
                    }"
                    >{{ $t('config.printer.title') }}
                </router-link>
                <router-link
                    class="item status"
                    v-if="levelThree() && hasSensorTool()"
                    :class="{ selected: currentPage == 'sensconfig' }"
                    :to="{
                        name: 'ConfigPage',
                        params: { page: 'sensconfig' }
                    }"
                    >{{ $t('config.sensor.title') }}
                </router-link>
                <router-link v-if="isManager() && !isAuditor" class="item dashboard" :class="{ selected: currentPage == 'dashboard' }" to="/config/dashboard">{{ $t('config.dashboard.title') }} </router-link>
                <router-link v-if="isManager() && canCreateUsers && !isAuditor" class="item users" :class="{ selected: currentPage == 'users' }" to="/config/users">{{ $t('config.users.title') }} </router-link>
                <router-link class="item status" :class="{ selected: currentPage == 'status' }" to="/config/status">{{ $t('config.status.title') }} </router-link>
                <!-- <router-link
                    class="item device"
                    :class="{ selected: currentPage == 'device' }"
                    to="/config/device"
                    >{{ $t("config.device.title") }}
                </router-link> -->
            </div>
        </template>
    </div>
</template>
<script>
import pPrinter from '@/components/domain/config/printer'
import sensor from '@/components/domain/config/sensor'
import pDevice from '@/components/domain/config/device'
import pLocation from '@/components/domain/config/location'
import pDev from '@/components/domain/config/dev'
import pDashboard from '@/components/domain/config/dashboard'
import pUsers from '@/components/domain/config/users'
import pStatus from '@/components/domain/config/status'
export default {
    name: 'Config',
    components: { pPrinter, pDevice, pLocation, pDev, pDashboard, pUsers, pStatus, sensor },
    props: {
        // update: { type: Boolean, default: false },
    },
    data() {
        return {
            update: false,
            config_id: undefined
        }
    },
    computed: {
        updateR: {
            get() {
                return this.update
            },
            set(value) {
                this.update = value
            }
        },
        isAuditor(){
            return this.$store.getters['loginUser/getAuditor']
        },
        configR: {
            get() {
                return this.config_id
            },
            set(value) {
                this.config_id = value
            }
        },
        currentPage() {
            var self = this
            var page = this.$route.params.page ? this.$route.params.page : 'location'
            this.updateR = this.$route.params.update ? this.$route.params.update : false
            this.configR = this.$route.params.config_id ? this.$route.params.config_id : undefined
            log('CONFIG, update -> ', this.updateR)
            log('CONFIG, config_id -> ', this.configR)
            if (page !== 'printer') this.$bar.hide()
            if (page) {
                if (page == 'printer' && this.hasAccess) {
                    return page
                } else if (page == 'location') {
                    return page
                } else if (page == 'sensconfig' && this.hasAccessLevel3) {
                    return page
                } else if (page == 'device') {
                    return page
                } else if (page == 'dev') {
                    return page
                } else if (page == 'dashboard') {
                    return page
                } else if (page == 'users' && this.hasAccessLevel3 && this.canCreateUsers) {
                    return page
                } else if (page == 'status') {
                    return page
                } else {
                    self.$popup.alert({
                        closable: false,
                        blocking: false,
                        message: self.$t('popup.access.not_level_permission'),
                        textSave: self.$t('popup.access.text_cancel'),
                        callSave: function (response) {
                            self.$router.push({ name: 'ConfigPage', params: { page: 'location' } })
                        }
                    })
                }
            } else {
                return false
            }
        },
        emp_id() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },
        currentEmployee() {
            if (this.emp_id) {
                var emp = this.$store.getters['employee/getEmployee'](this.emp_id)
            } else {
                var emp = this.$store.getters['loginUser/getLocalLocation']
            }
            return emp
        },
        hasAccess() {
            if (this.currentEmployee) {
                return this.$store.getters['hasAccess'](this.currentEmployee.id, 2)
            } else {
                return false
            }
        },
        hasAccessLevel3() {
            if (this.currentEmployee) {
                return this.$store.getters['hasAccess'](this.currentEmployee.id, 3)
            } else {
                return false
            }
        },
        tools() {
            var visible = []
            const config = this.$store.getters['login/getConfig']
            if (config.tools) {
                visible = config.tools
            }
            return visible
        },
        canCreateUsers() {
            return parseInt(this.$store.getters.getCanCreateUsers)
        }
    },
    methods: {
        redirectPath(permission, to) {
            var firstResult = this.$root.redirectPath(permission)
            return firstResult ? to : permission
        },
        isManager() {
            var idRoleUserLogged = this.currentEmployee.rol
            var role = this.$store.getters['employee/getRol'](idRoleUserLogged)
            if (role.level > 2) {
                return true
            } else {
                return false
            }
        },
        hasSensorTool() {
            let tools = this.$store.getters['login/getConfig'].tools
            //if tools has number 13 then return true
            return tools.includes(13)
        },
        levelThree() {
            var idRoleUserLogged = this.currentEmployee.rol
            var role = this.$store.getters['employee/getRol'](idRoleUserLogged)
            if (role.level >= 3) {
                return true
            } else {
                return false
            }
        }
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#fullcontent:not(.inLogin) {
    position: fixed;
}
#content.config {
    position: fixed;
    > .content {
        width: calc(100% - #{rem(200px)}) !important;
        .main-title {
            @include font-size(xl);
            text-align: center;
            margin: 55px 0px 18px;
            color: $main-dark;
            font-family: $text-light;
        }
        .main-description {
            @include font-size(ml);
            padding-bottom: 35%;
            display: block;
            text-align: center;
            color: $main;
            font-family: $text;
        }
    }
    @include small {
        > .content {
            width: calc(100% - #{rem(160px)}) !important;
        }
    }
    .categories {
        @include border-radius(8px);
        height: calc(100%) !important;
        width: rem(200px) !important;
        padding: 8px 5px;
        .item {
            @include interaction();
            @include background($size: 24px, $position: center right 10px);
            @include display-inline-flex();
            @include align-items();
            @include font-size(l);
            @include border-radius(5px);
            white-space: initial;
            font-family: $text;
            height: 60px;
            width: 100%;
            padding: 10px;
            color: $neutro-s90;
            &:hover {
                background-color: hover($inactive-t90);
            }
            &.selected {
                font-family: $text-bold;
                background-color: $main;
                color: #ffffff;
                &:hover {
                    background-color: hover($main);
                }
            }
        }
    }
    @include small {
        .categories {
            width: rem(160px) !important;
            .item {
                @include font-size(ml);
            }
        }
    }
    &.users {
        &.landscape {
            .content {
                width: calc(100% - #{rem(160px)}) !important;
                height: 100% !important;
                padding: 0px !important;
            }
        }
        &.desktop {
            .content {
                width: calc(100% - #{rem(200px)}) !important;
                height: 100% !important;
                padding: 0px !important;
            }
        }
    }
}
</style>
// PORTRAIT STYLES
<style lang="scss">
#content.config.portrait {
    > .content {
        width: 100% !important;
    }
}
</style>
